.keyboardContainer {
    display: flex;
    max-width: 1400px;
    width: 100%;
    height: 350px;
    padding: 5px;
    background-color: #ececec;
    border-radius: 5px;
}

.keyboardCol {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.keyboardCol1 {
    /* background-color: blue; */
    width: 66%;
}

.keyboardCol2 {
    width: 16%;
    padding-left: 5px;
}

.keyboardCol3 {
    width: 18%;
    padding-left: 5px;
    display: flex;
}

.keyboardSubCol1 {
    width: 75%;
}

.keyboardSubCol2 {
    width: 25%;
    margin-left: 2px;
}

.keyboardRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 15%;
}

.keyboardKey {
    box-shadow: 0 0 3px -1px rgb(0 0 0 / 30%);
    /* height: 40px; */
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px;
    background: #fff;
    border-bottom: 1px solid #b5b5b5;
    /* cursor: pointer; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* -webkit-tap-highlight-color: rgba(0,0,0,0); */
    margin: 1px;
    transition: all 0.1s cubic-bezier(0.95, 0.05, 0.795, 0.035);
}

.keyNotClicked {
    /* background-color: green; */
}

.keyClicked {
    background-color: rgba(0,0,0,0.2);;
}

.ColTopRow {
    margin-bottom: 40px;
}

.ArrowKeySec {
    margin-top: 60px;
    display: flex;
    justify-content: center;
}

.numPadMargin {
    margin-top: 100px;
}

@media only screen and (max-width: 1100px) {
    .keyboardKey > p {
      font-size: 10px;
    }
  }

  @media only screen and (max-width: 768px) {
    .keyboardContainer {
        height:300px
    }
  }