.MicTestContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  width: calc(100% - 20px);
  /* margin-right: 5%; */
  padding: 5px;
  background-color: #ececec;
  border-radius: 5px;
}

.MicTestContainer > div {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.soundWaveComponent {
  height: 100%;
  width: 100%;
}

#visualizer {
  border-radius: 5px;
}

svg {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;;
  bottom: -1px;
}
  
path {
  stroke-linecap: square;
  stroke: white;
  stroke-width: .7px;
  /* margin-right: .2px; */
}

@media only screen and (max-width: 768px) {
  .MicTestContainer {
      height:300px
  }
}