.homeContentContainer {
  display: flex;
  /* justify-content: center;
  align-items: center; */
  min-height: calc(100vh - 95px);
}

.homeCenterContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.TestComponentContainer {
  /* margin-top: 50px; */
  width: 98%;
  margin: 20px 0px;
  /* min-height: calc(100vh - 95px); */
  /* width: 75%; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.leftAdContainer {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.leftAdContainer > iframe {
  margin-right: 20px;
}

.rightAdContainer {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.rightAdContainer > iframe {
  margin-left: 20px;
}

.TestComponentContainer > div {
  display: flex;
}

.TestComponentContainer > div:first-child {
  width: calc(100% + 10px);
  max-width: 1410px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.TestComponentContainer > div:first-child > div {
  display: flex;
  width: 50%;
}

.TestComponentContainer > div:first-child > div:last-child {
  justify-content: flex-end;
}

.TestComponentContainer > div:last-child {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.displaySizeErr {
  text-align: center;
  padding: 0px 40px;
  padding-bottom: 100px;
}

.websiteAboutContentContainer {
  padding: 0px 60px;
  max-width: 1400px;
  padding-bottom: 60px;
}

.websiteAboutContentContainer > p {
  font-size: 16px;
  line-height: 1.5rem;
}

@media only screen and (max-width: 1024px) {
  .rightAdContainer, .leftAdContainer {
    display: none;
  }

  .TestComponentContainer {
    width: 85%;
  }
}


@media only screen and (max-width: 670px) {
  .homeContentContainer {
    min-height: calc(100vh - 168px);
  }
}