.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  -webkit-box-shadow: 0 0 10px 0 rgb(0 0 0 / 50%);
  -moz-box-shadow: 0 0 10px 0 rgba(0,0,0,.5);
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 50%);
  background-color: #fff;
  margin-bottom: 20px;
}

a {
  color: unset;
  text-decoration: none;
}

.companyName {
  margin-left: 40px;
  font-size: 35px;
  font-weight: 500;
}

.headerContainer > div {
  display: flex;
  margin-right: 40px;
}

.contactLinks {
  margin-left: 20px;
  font-size: 18px;
  cursor: pointer;
}

.contactLinks:hover {
  text-decoration: underline;
}


@media only screen and (max-width: 768px) {
  .contactLinks {
    font-size: 15px;
    margin-left: 15px;
  }
}

@media only screen and (max-width: 670px) {
  .headerContainer {
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 10px;
  }

  .companyName {
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: 30px;
  }

  .contactLinks {
    font-size: 18px;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .headerContainer > div {
    display: flex;
    flex-direction: column;
    margin-right: 0px;
  }

}
