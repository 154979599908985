.WebCamTestContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  width: calc(100% - 20px);
  /* margin-left: 4%; */
  padding: 5px;
  background-color: #ececec;
  border-radius: 5px;
}

.WebCamTestContainer > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

@media only screen and (max-width: 768px) {
  .WebCamTestContainer {
      height:300px
  }
}